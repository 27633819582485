.App {
  background-color: white;
  font-family: "Inter", sans-serif;
  padding-bottom: 30px;
}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.5); /* Black with opacity */
}

/* Modal Content */
.modal-content {
  text-align: center;
  background-color: #fefefe;
  margin: 13% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 400px; /* Could be more or less, depending on screen size */
  border-radius: 8px;
}

.modal-content>div {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  justify-content: center;
}

.modal-content>div>button {
  width: 48%;
  height: 45px;
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;
  border-radius: 6px;
  color: black;
}
.modal-content>div>button:last-child {
  background-color: green;
  color: white;
}

/* Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: -10px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


@media (max-width: 800px) {
  .modal-content{
    margin: 50% auto;
    width: 80%;
  }
}