.inputMain {
    width: 85%;
    height: 50px;
    border: 1px solid #E4E8EE;
    border-radius: 4px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 15px;
    padding-left: 1px;
    background: #777e8859;
    opacity: 0.5;
}

.inputMain>input {
    width: 92%;
    height: 95%;
    border: none;
    outline: none;
    padding-left: 30px;
    font-size: 16px;
    color: #6E7583;
    background: #E4E8EE59;
}

.inputMain>img {
    cursor: pointer;
}

.inputMain>input::placeholder {
    color: #01050e;
}

.enabled {
    background-color: white;
    border: 1px solid #E4E8EE;
    border-radius: 8px;
    box-shadow: 0px 1px 25px 0px #00000038;
    opacity: 1;
}

.enabled>input {
    background-color: white;
}

@media (max-width: 800px) {
    .inputMain>input {
        width: 75%;
    }
}