.mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    box-shadow: 0px -8px 25px 0px #00000038;
}

.mainHeader>div {
    display: flex;
    align-items: center;
    gap: 30px;
}

.mainHeader>div>div {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 39px;
    color: #0FA958;
}

.mainHeader>div>label {
    width: 210px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    background-color: white;
    border: 1.5px solid black;
    border-radius: 4px;
    cursor: pointer;
}

@media (max-width: 800px) {
    .mainHeader>div>label>p {
        display: none;
    }

    .mainHeader>div>label {
        width: 50px;
    }

    .mainHeader {
        padding: 20px;
        flex-wrap: wrap;
        gap: 20px;
    }

    .mainHeader>div {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        /* column-gap: 10px; */
    }

    .mainHeader>img:first-child {
        width: 130px;
    }

    /* .mainHeader>div>div>p {
        width: 50px;
        height: 30px;
        overflow: hidden;
    } */
}