.chatMain {
    height: 63vh;
    padding: 50px 80px;
    overflow-y: scroll;
    margin-bottom: 10px;
    scrollbar-width: none;
}

.chatMain>div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.chatMain>.suggested {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;

    &>p {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        padding: 10px 15px;
        border-radius: 24px;
        border: 0.2px solid #24242499;
        margin: 0px;

        &>span {
            cursor: pointer;
        }
    }
}

.chatMain::-webkit-scrollbar {
    display: none;
}

.chatMain>p {
    text-align: center;
    color: #0FA958;
}

.noPdf {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0px 5px;
}

.noPdf>div {
    margin-right: auto;

    &>h6 {
        font-size: 16px;
        margin: 0px;
    }

    &>p {
        font-size: 15px;
        margin-top: 10px;
    }
}

.noPdf>label {
    width: 250px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    background-color: white;
    border: 1.5px solid black;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 auto;
}

.noPdf>label>img {
    width: 45px;
    height: 45px;
    margin-top: 10px;
}

@media (max-width: 800px) {
    .chatMain {
        height: 63vh;
        padding: 20px 10px;
    }
}

@media (min-height: 850px) {
    .chatMain {
        height: 75vh;
        padding: 20px 10px;
    }
}
